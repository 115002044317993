import { Atom, Cmp, Mod } from ":mods";
import { createStore } from "solid-js/store";
import { For, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import { AMaterialTaskContent, MaterialTaskDesignView } from "../../../../models";
import { checkOptions } from "../methods";

type RecievedPayload = AMaterialTaskContent<{
  image: any;
  description$: string;
  description?: string;
  title$: string;
  title?: string;
  sub_title$: string;
  option_one?: { id: number; value: string }[];
  option_two?: { id: number; value: string }[];
  option_one_answer?: { id: number; value: string };
  option_two_answer?: { id: number; value: string };
}>;
type RequestPayload = AMaterialTaskContent<{
  image?: boolean;
  title?: string;
  title$: string;
  description?: string;
  description$?: string;
  option_one: number;
  option_two: number;
  option_one_answer?: { id: number; value: string };
  option_two_answer?: { id: number; value: string };
}>;
type RequestPayloadSection = Partial<RequestPayload[0]>;
type RequestPayloadSectionContent = Partial<RequestPayloadSection["content"]>;
type RequestPayloadSectionContentItem = Partial<RequestPayloadSectionContent[0]>;
// -------------
//? curation
// -------------
export default function (props: MaterialTaskDesignView<RecievedPayload, RequestPayloadSectionContent>) {
  // setup vars before content
  let images = [];

  // track parent content
  const content = createMemo(() => {
    // on content updated or discarded
    images = [];
    console.log("sadge tp 16 :: ", props.elements.data);
    return props.elements.data.content as RecievedPayload;
  });

  function onDataChange(idx: number, props: RequestPayloadSectionContentItem) {
    const items = content()[0].content as unknown as RequestPayloadSectionContent;
    items[idx] = { ...items[idx], ...props };
    // ? this is where you process data before passing it to update
    for (const item of items) {
      // const new_item = { ...item, ...props } as RequestPayloadSectionContentItem;
      if ("image" in item && typeof item.image !== "boolean") {
        item.image = false;
      }
      checkOptions(item);
      // pass data to update
    }
    console.log("d :: ", items);
    onDataUpdate(items);
  }

  function onDataUpdate(data: RequestPayloadSectionContent) {
    const formData = new FormData();
    images.map((imagefile, i) => formData.append("images", imagefile, `tp16 image ${i + 1}`));
    formData.append("data", JSON.stringify(data));
    props.events?.onDataUpdate?.(formData, data);
  }

  return (
    <form>
      <For each={content()}>
        {(section, index) => {
          return (
            <>
              <h1 class="text-39px font-700 mt-40px mb-20px py-20px">
                {section.details.title || section.details[0]?.title || "Curation"}
              </h1>
              <section class="flex flex-wrap w-full pr-0.8rem gap-20px">
                <For each={section.content}>
                  {(content, idx) => {
                    return (
                      <div class={`flex gap-20px w-full`}>
                        <div class="flex flex-col w-59% gap-15px">
                          {!!content.title$ && !!content.sub_title$ && (
                            <>
                              <Atom.Tasks.Title
                                class="text-61px leading-43px  h-20% w-412px "
                                elements={{
                                  title: content.title$,
                                }}
                                options={{
                                  editable: false,
                                }}
                              />
                              <Atom.Tasks.Subtitle
                                class="uppercase"
                                elements={{
                                  sub_title: content.sub_title$,
                                }}
                                options={{
                                  editable: false,
                                }}
                              />
                              <Atom.Tasks.Description
                                class="w-full "
                                elements={{
                                  description: content.description$,
                                }}
                                options={{
                                  editable: false,
                                }}
                              />
                            </>
                          )}

                          {!!content.title$ && idx() > 1 && (
                            <Atom.Tasks.Title
                              class="text-39px leading-30px w-320px my-7px  h-164px "
                              elements={{
                                title: content.title$,
                              }}
                              options={{
                                editable: false,
                              }}
                            />
                          )}

                          {!!content.option_one && (
                            <Atom.Tasks.Dropdown
                              placeholder="Primary / Secondary Object"
                              class="w-100% h-53px "
                              elements={{
                                options: content.option_one,
                                opt_ans: content.option_one_answer?.id,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                // @ts-ignore
                                onValueChange: (e) => onDataChange(idx(), { option_one_answer: e }),
                              }}
                            />
                          )}
                        </div>

                        <div class="w-full h-full">
                          {!!content.image && (
                            <div class="w-full h-66vh">
                              <Atom.Tasks.Image
                                class="w-full h-full"
                                options={{
                                  addImagePlaceholder: "Upload Partner Logo or Image Here",
                                  editable: props.options.editable,
                                }}
                                elements={{
                                  images: images,
                                  image: content.image,
                                }}
                                events={{
                                  onImageChange: (e) => onDataChange(idx(), { image: e }),
                                }}
                              />
                            </div>
                          )}

                          {!!content.option_two && (
                            <Atom.Tasks.Dropdown
                              placeholder="Select Narrative  Part - Start / Middle / End"
                              class="w-full h-53px "
                              elements={{
                                options: content.option_two,
                                opt_ans: content.option_two_answer?.id,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                // @ts-ignore
                                onValueChange: (e) => onDataChange(idx(), { option_two_answer: e }),
                              }}
                            />
                          )}

                          {!!content.title$ && idx() > 1 && (
                            <Atom.Tasks.Description
                              class="w-full h-164px"
                              elements={{
                                description: content.description,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }}
                </For>
              </section>
            </>
          );
        }}
      </For>
    </form>
  );
}
